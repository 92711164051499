import React from 'react';
import '../Static/Style/TermsConditionsCss/TermsConditions.css'

function TermsAndConditions() {
  return (
    <div className="terms-and-conditions-box">

    <div className="terms-and-conditions">


     
      <h2>Terms and Conditions and Privacy Policy with Bangalore, India as the jurisdiction</h2>

<p> 

<h3>Colabrary Terms and Conditions</h3>

  

<h4>1. Introduction</h4>

Welcome to Colabrary! These Terms and Conditions govern your use of our platform. By accessing or using Colabrary, you agree to comply with these terms. 

  

<h4>2. Educational Content Policy</h4>

<p><strong>Content Requirements: </strong> Users must only upload educational content relevant to learning and academic topics. All non-educational, explicit, violent, or inappropriate content is prohibited. </p>

<p><strong>Prohibited Content:</strong> 

   <p> <li>Includes but is not limited to</li> </p>

  <p><li>Sexual, explicit, or pornographic material.</li> </p>

  <p><li>Hate speech, discriminatory remarks, or content promoting violence or illegal activities.</li> </p>

  <p><li> Any content unrelated to educational purposes.</li> </p>
  </p>

<strong>Consequences: </strong>Violations may result in account suspension, permanent ban, legal actions, and penalties up to ₹5 lakhs. 

  

<h4>3. Copyright and Intellectual Property</h4>

<p><strong>User Responsibility:</strong> Ensure all uploaded content is original or properly licensed. Unauthorized use of copyrighted materials is forbidden. </p>

<p> <strong>Colabrary’s Rights:</strong> We reserve the right to remove infringing content and pursue legal action against repeat offenders. </p>

  

<h4>4. User Accounts</h4>

<p><strong>Registration:</strong> Users must provide accurate information during registration. You are responsible for maintaining the confidentiality of your account credentials. </p>

<p><strong>Termination:</strong> Colabrary may terminate or suspend accounts for violations of these terms without notice. </p>

  

<h4>5. Privacy and Data Protection</h4> 

<p><strong>Data Collection:</strong> We collect personal data necessary to provide our services and improve user experience. </p>

<p><strong>Data Use:</strong> Your data will be used in accordance with our Privacy Policy and not shared with third parties without consent. </p>

<p><strong>Security:</strong> We implement reasonable security measures to protect your data from unauthorized access. </p>

  

<strong>6. User Conduct</strong>

<p><strong>Respect and Safety:</strong> Users must engage respectfully and refrain from any form of harassment or abusive behavior.</p> 

<p> <strong>Reporting Violations:</strong> Report any violations of these terms using our reporting system. We will address reports promptly. </p>

  

<h4>7. Liability and Indemnity</h4>

<p> <strong>User Liability:</strong> You agree to indemnify Colabrary against any claims or damages arising from your use of the platform. </p>

<p> <strong>Limitation of Liability:</strong> Colabrary is not liable for any indirect or consequential damages arising from your use of the platform. </p>

  

<h4>8. Changes to Terms</h4>

<strong>Modifications:</strong> Colabrary may update these Terms and Conditions periodically. Continued use of the platform constitutes acceptance of any changes. 

  
<h4>9. Governing Law and Jurisdiction</h4>

<strong>Jurisdiction:</strong> These terms are governed by and construed in accordance with the laws of Bangalore, India. Disputes will be resolved in the competent courts of Bangalore, India. 

  

<h3>Colabrary Privacy Policy</h3>

  

<h4>1. Introduction</h4>

<p>Colabrary values your privacy. This Privacy Policy explains how we collect, use, and protect your personal information. </p>

  
<h4>2. Information We Collect</h4>

<p> <strong>Personal Information:</strong> Includes name, email address, and other contact details provided during registration. </p>

<p> <strong>Usage Data:</strong> Includes information about your interactions with the platform, such as IP address, device information, and browsing activity. </p>

  

<h4>3. How We Use Your Information</h4>

<p> <strong>To Provide Services:</strong> Personal information is used to manage your account and deliver educational content. </p>

<p><strong>To Improve Services:</strong>Usage data helps us understand how you use the platform and enhance user experience. </p>

<p> <strong>For Communication:</strong> We may use your contact information to send updates and notifications related to Colabrary. </p>

  
<h4>4. Sharing Your Information</h4>

<p> <strong>Third-Party Services:</strong> We do not share your personal information with third parties except as required by law or to provide our services. </p>

<p> <strong>Legal Obligations:</strong> We may disclose information to comply with legal requirements or to protect the rights and safety of Colabrary and its users. </p>

  

<h4>5. Data Security </h4>

<p> <strong>Protection Measures:</strong> We implement industry-standard security measures to protect your data from unauthorized access or breaches. </p>

<p> <strong>Data Retention:</strong> We retain personal information for as long as necessary to fulfill the purposes for which it was collected. </p>

  

<h4>6. Your Rights</h4>

<p> <strong>Access and Correction:</strong> You have the right to access and update your personal information. </p>

<p> <strong>Data Deletion:</strong> You can request the deletion of your personal data, subject to legal and contractual obligations. </p>

  

<h4>7. Cookies and Tracking Technologies</h4>

<p><strong>Use of Cookies:</strong> We use cookies to enhance your experience and track platform usage. You can manage cookie preferences through your browser settings. </p>

  

<h4>8. Changes to Privacy Policy</h4>

<p> <strong>Modifications:</strong> We may update this Privacy Policy periodically. Continued use of the platform signifies acceptance of any changes. </p>

  
<h4>9. Contact Us</h4>

    <p> <strong>Inquiries:</strong> For questions or concerns about this Privacy Policy, please contact us at [Your Contact Information]. </p>

  

{/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

  

 
      </p>
      {/* <button className="accept-terms-button">Accept Terms</button> */}
    </div>
    </div>


  );
}

export default TermsAndConditions;