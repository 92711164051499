import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../../Static/Style/yuSingleVideo.css';
import userIMG from "../../assets/img3.jpg";
import YuVideoList from './YuVideoList';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton,
    LoadingSpinner,
    ClosedCaptionButton

} from 'video-react';
import 'video-react/dist/video-react.css';
import MyLoader from '../Loader/MyLoader';
import LoadingErrorPage from '../LoadingErrorPage/LoadingErrorPage';




// const thumbnail = "https://img.youtube.com/vi/nSb5QdG2PCE/hqdefault.jpg";


const YuSingleVideo = () => {
    const { getSingleVideos, currentVideo, postVideoComment,postLikeVideo } = useVideoStore();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { videoId } = useParams();
    const [likes, setLikes] = useState(0);
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    useEffect(() => {
        const fetchSingleVideo = async () => {
            try {
                const videoResponse = await getSingleVideos(videoId);
                setLoading(false);
            } catch (error) {
                setError('Error fetching video');
                setLoading(false);
            }
        };

        fetchSingleVideo();
    }, []);

    //   http://127.0.0.1:8000/media/videos/123456_RXXjx35.mp4

    // /media/videos/123456.mp4

    if (loading) {
        return <div><MyLoader /></div>;
    }

    if (error) {
        return <div><LoadingErrorPage /></div>;
    }



    const handleLike = async () => {

        try {
        const value = "1"
          const Likeresponse = await postLikeVideo(videoId, value);
          console.log('Liked video:', Likeresponse.data);
          setLikes(likes + 1); 
        } catch (error) {
          console.error('Failed to like the video:', error);
        }
      };
      
      const handleDislike = async () => {
        try {
            const value = "-1"
              const DisLikeresponse = await postLikeVideo(videoId, value);
              console.log('Disliked video:', DisLikeresponse.data);
              setLikes(likes - 1); 
            } catch (error) {
              console.error('Failed to like the video:', error);
            }
      };
      
      
      

    const handleShare = () => alert('Video shared!');
    const handleSave = () => alert('Video saved!');

    const handleComment = async () => {
        if (newComment.trim() !== '') {
          const updatedComments = [...comments, newComment];
      
          try {
          const commentResponse = await postVideoComment(videoId, newComment);
          console.log(commentResponse)

          setComments(updatedComments);
          setNewComment('');
          } catch (error) {
            console.error('Failed to post comment:', error);
          }
        }
      };
      

    return (
        <>
            <div className='yu-single-video-container'>
                <div className='yu-single-video-all'>
                    <div className='yu-single-video'>
                        <div className='video-player'>

                            <Player poster={`http://127.0.0.1:8000${currentVideo.thumbnail}`} 
                            className='yu-player-frame'
                                startTime={300} >
                                <source src={`http://127.0.0.1:8000${currentVideo.video}`} />

                                <track
                                    kind="captions"
                                    src="/assets/elephantsdream/captions.en.vtt"
                                    srcLang="en"
                                    label="English"
                                    default
                                />


                                <BigPlayButton position="center" />
                                <LoadingSpinner />

                                <ControlBar autoHide={false} className="my-class" >
                                    <ReplayControl seconds={10} order={1.1} />
                                    <ForwardControl seconds={30} order={1.2} />
                                    <CurrentTimeDisplay order={4.1} />
                                    <TimeDivider order={4.2} />
                                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                    <VolumeMenuButton disabled />
                                    <VolumeMenuButton vertical />
                                    <ClosedCaptionButton order={7} />



                                </ControlBar>
                            </Player>
                        </div>

                        <div className='video-details'>
                            <div className='yu-video-info'>
                                <h1>{currentVideo.name}</h1>
                                <div className='yu-channel-info'>
                                    <div>
                                        <img src={userIMG} alt="Channel" />
                                        <h4>{currentVideo.id}</h4>
                                    </div>

                                    <div className="video-actions">
                                        <span onClick={handleLike} className='like-action'>
                                            <span className='yu-like-icon'></span> <span className='text'> Like {likes}</span>
                                        </span>
                                        <span onClick={handleDislike} className='like-action'>
                                            <span className='yu-dislike-icon'></span> <span className='text'> DisLike {0}</span>
                                        </span>
                                        <span onClick={handleShare} className='share-action'>
                                            <span className='yu-share-icon'></span> <span className='text'>Share</span>
                                        </span>
                                        <span onClick={handleSave} className='save-action'>
                                            <span className='yu-saved-video'></span> <span className='text'>Save</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='comment-container'>
                            <h3>{comments.length} Comments</h3>
                            <div className='info'>
                                <img src={userIMG} alt="User" onClick={() => setShowComments(!showComments)} />
                                <div className="add-comment">
                                    <input
                                        type="text"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        placeholder="Add a comment..."
                                    />
                                    <button onClick={handleComment}>Comment</button>
                                </div>
                            </div>

                            {showComments && (
                                <div className="comments-list">
                                    {comments.map((comment, index) => (
                                        <div key={index} className="comment-item">
                                            <div className="info">
                                                <img src={userIMG} alt="User" />
                                                <div className='comment-info'>
                                                    <div>
                                                        <h5>@User Name</h5>
                                                        <span>Just now</span>
                                                    </div>
                                                    <div className="comment-text">
                                                        {comment}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='yu-video-list-container'>
                        <YuVideoList />
                    </div>
                </div>

            </div>
        </>
    );
};

export default YuSingleVideo;

