import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Static/Style/CreateChannel.css';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';

const CreateChannel = ({ onClose }) => {
    const [channelName, setChannelName] = useState('');
    const [channelDescription, setChannelDescription] = useState('');
    const [loading, setLoading] = useState(false); 
    const navigate = useNavigate();
    const { createChannel } = useChannelStore();
    const {currentUser} = useAccountStore();
    // const userId = localStorage.getItem('userId');


    const handleSubmit = async (event) => {
        event.preventDefault();  
        setLoading(true);  
    
        try {
            const response = await createChannel({
                user: currentUser.id,
                name: channelName,
                description: channelDescription,
            });
    
            console.log("Channel Created:", response);  

    
            toast.success("Channel Created Successfully");
            onClose();
            // localStorage.setItem('channelID', response.data.id);

    
            navigate('/channel');
        } catch (error) {
            console.error('Error creating channel:', error);

            if (error.response && error.response.data) {
                const errorData = error.response.data;
    
                const message = errorData.name ? 
                    ` ${errorData.name.join(', ')}`
                    : errorData.description ? 
                    `${errorData.description.join(', ')}`
                    : errorData.user ? 
                    `User Error: ${errorData.user.join(', ')}`
                    : errorData.message || 'Create Channel failed, Please Try Again';
    
                toast.error(message);
            } else {
                toast.error('Create Channel failed, Please Try Again');
            }
        } finally {
            setLoading(false); 
        }
    };
    

    return (
        <div className='create-channel-form'>
            <h2>Create Channel</h2>
            <form onSubmit={handleSubmit}>
                <div className='create-channel-form-group'>
                    <label htmlFor='channelName'>Channel Name</label>
                    <input
                        type='text'
                        id='channelName'
                        value={channelName}
                        onChange={(e) => setChannelName(e.target.value)}
                        required
                    />
                </div>
                <div className='create-channel-form-group'>
                    <label htmlFor='channelDescription'>Description</label>
                    <textarea
                        id='channelDescription'
                        value={channelDescription}
                        onChange={(e) => setChannelDescription(e.target.value)}
                        required
                    />
                </div>
                <button type='submit' disabled={loading}>
                    {loading ? 'Creating...' : 'Create Channel'}
                </button>
            </form>
        </div>
    );
};

export default CreateChannel;
