import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import '../../Static/Style/OwnChannel.css';
import channelProfileImg from '../../assets/image2.jpg';
import moment from 'moment';
import MyLoader from '../Loader/MyLoader';
import LoadingErrorPage from '../LoadingErrorPage/LoadingErrorPage';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import { useNavigate } from 'react-router-dom';

const OwnChannel = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [channelLoaded, setChannelLoaded] = useState(false);
  const navigate = useNavigate();

  const { getAllVideos, currentVideo } = useVideoStore();
  const { getChannel, currentChannel } = useChannelStore();

  const handleVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  useEffect(() => {
    const fetchChannelOwnVideo = async () => {
      try {
        const channelID = "6";
        console.log(currentChannel)

        await getChannel(channelID); 
        setChannelLoaded(true);

        await getAllVideos();
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchChannelOwnVideo();
  }, [getAllVideos, getChannel]);

  if (loading) {
    return <div className='full-page-loader'><MyLoader /></div>;
  }

  if (error) {
    return <div><LoadingErrorPage /></div>;
  }

  const sortedVideos = [...currentVideo].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  const placeholderImage = 'https://via.placeholder.com/300x200.png?text=Loading+Thumbnail';

  return (
    <div className='own-channel-container'>
      <div className='own-channel'>
        <div className='own-channel-header'>
          <div className='channel-header'>
            <div className='channel-img'>
              <img src={channelProfileImg} alt="Channel Profile" />
            </div>
            <div className='channel-info'>
              <h1>{currentChannel?.name}</h1>
              <p>{currentChannel?.description}</p>
            </div>
          </div>

          <div className='channel-nav'>
            <ul>
              <li>Home</li>
              <li>Videos</li>
              <li>Playlist</li>
            </ul>
          </div>
        </div>

        {channelLoaded && (
          <div className='channel-content'>
            <h3>Uploads</h3>
            <div className='channel-content-all'>
              {sortedVideos.length > 0 ? (
                sortedVideos.map((video) => (
                  <div
                    key={video.id}
                    onClick={() => handleVideoClick(video.id)}
                    className='content-all-video-item'
                  >
                    <ReactPlayer
                      url={video.video}
                      light={video.thumbnail ? `http://127.0.0.1:8000/${video.thumbnail}` : placeholderImage}
                      width='100%'
                      height='150px'
                    />
                    <div className='video-info'>
                      <h4 className='video-title'>{video.name}</h4>
                      <p className='video-details'>
                        {video.views} views • {moment(video.timestamp).fromNow()}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No videos available.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OwnChannel;
