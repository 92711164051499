import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Auth from '../Auth/Auth';
import '../../Static/Style/YuRightComponent.css';
import CreateChannel from './CreateChannel';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import userImg from '../../assets/image1.jpg'


const YuRightComponent = () => {
    const [isCreateChannelVisible, setIsCreateChannelVisible] = useState(false);
    const [isAuthPopupVisible, setIsAuthPopupVisible] = useState(false);
    const popupRef = useRef(null);
    const navigate = useNavigate();

    const {currentUser, isLoggedIn ,userlogout} = useAccountStore();

    const openAuthPopup = () => {
        console.log("Login button clicked");
        setIsAuthPopupVisible(true);
    };

    const handleCreateChannelClick = () => {
        setIsCreateChannelVisible(true);
    };

    const handleCloseCreateChannel = () => {
        setIsCreateChannelVisible(false);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsCreateChannelVisible(false);
        }
    };

    const handleYourChannelClick = () => {
        navigate('/channel');
    };
    const handleMyVideolClick = () => {
        navigate('/yu-my-videos');
    };

    const handleHomeClick = () => {
        navigate('/');
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAuthClose = () => {
        setIsAuthPopupVisible(false);
    };

    return (
        <>
            <div className='yu-right-side'>
                <div className='yu-right-items'>

                {isLoggedIn && currentUser? (
                <div className="yu-user-info">
                    <img src={userImg} alt="User Profile" className="profile-image" />
                    <p className='yu-user-info-name'>{currentUser.name}</p>
                    <p className='yu-user-info-userName'>{currentUser.email}</p>
                    <button className="yu-user-logout" onClick={userlogout}>Logout</button>
                </div>
            ) : (
                <h5 className='login-yu-icon' title='Login' onClick={openAuthPopup}>Login</h5>
            )}




                    <h5 onClick={handleHomeClick}><span className='yu-home-icon'></span>Home</h5>
                    {/* <h5 onClick={handleMyVideolClick} ><span className='yu-video-icon'></span>My Videos</h5> */}
                    {/* <h5><span className='yu-saved-video'></span>Saved</h5> */}
                    {/* <h5><span className='yu-watch-later'></span>Watch Later</h5> */}
                    {/* <h5><span className='yu-like-icon'></span>Liked Videos</h5> */}
                    {/* <h5><span className='yu-history-icon'></span>History</h5> */}

                    {/* <h5 onClick={handleYourChannelClick}>
                        <span className='craete-channel-icon'></span>
                        Your Channel
                    </h5> */}
                    <h5 onClick={handleCreateChannelClick}>
                        <span className='craete-channel-icon'></span>
                        Create Channel
                    </h5>

                    {/* <h5><span className='yu-setting-icon'></span>Settings</h5> */}
                    <div className='yu-terms-policy'>
                    <span className='yu-terms-condition-policy'><Link to="/terms-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</Link></span>
                    <span className='yu-terms-condition-policy'><Link to="/terms-conditions" target="_blank" rel="noopener noreferrer">YU-Policy</Link></span>
                    
                    </div>

                </div>
            </div>

            {isCreateChannelVisible && (
                <>
                    <div className="modal-overlay"></div>
                    <div className='create-channel-modal' ref={popupRef}>
                        <button className='close-modal' onClick={handleCloseCreateChannel}>X</button>
                        <CreateChannel onClose={handleCloseCreateChannel} />
                    </div>
                </>
            )}

            {isAuthPopupVisible && (
                <Auth onClose={handleAuthClose} />
            )}
        </>
    );
};

export default YuRightComponent;
