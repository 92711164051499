import { Routes, Route } from 'react-router-dom';
import React, { useState } from 'react';
import YuContainer from '../Components/YuContainer/YuContainer';
import YuSingleVideo from '../Components/YuContainer/YuSingleVideo';
import '../Static/Style/YuPage.css';
import OwnChannel from '../Components/YuContainer/OwnChannel';
import YuTopNav from '../Components/YuContainer/YuTopNav';
import YuRightComponent from '../Components/YuContainer/YuRightComponent';
import YuSearchResults from '../Components/YuContainer/YuSearchResults';

const YuPage = () => {
  const [isRightComponentVisible, setIsRightComponentVisible] = useState(false);

  const toggleRightComponent = () => {
    setIsRightComponentVisible(!isRightComponentVisible);
  };

  return (
    <div className="Yu-box">
      <div
        className={`yu-top-nav-con ${
          isRightComponentVisible ? 'visible' : 'hidden'
        }`}
      >
        <YuTopNav
          toggleRightComponent={toggleRightComponent}
          isRightComponentVisible={isRightComponentVisible} // Pass state as prop
        />
      </div>
      <div className="yu-right-container">
        <div
          className="right-box"
          style={{
            width: isRightComponentVisible ? '70%' : '100%',
          }}
        >
          <Routes>
            <Route path="/" element={<YuContainer />} />
            <Route path="channel" element={<OwnChannel />} />
            <Route path="video/:videoId" element={<YuSingleVideo />} />
            <Route path="result" element={<YuSearchResults />} />

          </Routes>
        </div>
        <div
          className={`yu-right-component ${
            isRightComponentVisible ? 'visible' : 'hidden'
          }`}
        >
          <YuRightComponent />
        </div>
      </div>
    </div>
  );
};

export default YuPage;
