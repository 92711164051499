import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import YuPage from './Pages/YuPage';
import YuMyVideosPage from './Pages/YuMyVideosPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAccountStore } from './Store/UserStore/AccountStore';
import TermsAndConditions from './Pages/TermsAndConditions';


const App = () => {

    const { userDataGet } = useAccountStore();

    useEffect(() => {
      const token = localStorage.getItem('authToken');
      if (token) {
        userDataGet(token)
          .catch(() => {
            localStorage.removeItem('authToken');
          });
      }
    }, [userDataGet]);

  return (
  <>
  <div>
  <Router>
  <Routes>
  <Route path="/*" element={<YuPage />} />
  <Route path="/yu-my-videos/*" element={<YuMyVideosPage />} />
  <Route path="/terms-conditions/*" element={<TermsAndConditions />} />

  </Routes>
  </Router>


  <ToastContainer
      hideProgressBar
      theme="colored"
      />

  </div>
  
  </>
  )
}

export default App

