import { create } from 'zustand';
import axios from 'axios';

export const useVideoStore = create((set) => ({
  currentVideo: [],
  token: null,

  uploadVideo: async (payload, onUploadProgress) => {
    const token = localStorage.getItem('authToken');
  
    try {
      const response = await axios.post(
        'http://127.0.0.1:8000/yu/upload-video/',
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress,
        }
      );
  
      return response.data;
    } catch (error) {
      console.error('Error uploading video:', error.response ? error.response.data : error.message);
      throw error;
    }
  },
  

  getAllVideos: async () => {
    
    try {
      const response = await axios.get('http://127.0.0.1:8000/yu/get-all-video/', {},);
      set({ currentVideo: response.data });
      // console.log(response);
    } catch (error) {
      console.error('Error Video API:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getSingleVideos: async (videoId) => {
    try {
      const response = await axios.get(`http://127.0.0.1:8000/yu/get-video/${videoId}/`);
      set({ currentVideo: response.data });
    } catch (error) {
      console.error('Error fetching video:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  postVideoComment: async (videoId, newComment) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found');
      return;
    }
  
    try {
      const response = await axios.post(
        `http://127.0.0.1:8000/yu/comment-video/${videoId}/`, 
        { comment: newComment },  
        {
          headers: {
            Authorization: `Token ${token}`, 
          },
        }
      );
      return response;
    } catch (error) {
      console.error('Error posting comment:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  postLikeVideo: async (videoId, value) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found');
      return;
    }
  
    try {
      const response = await axios.post(
        `http://127.0.0.1:8000/yu/like-video/${videoId}/`,
        { value },
        {
          headers: {
            Authorization: `Token ${token}`,
          }
        }
      );
      console.log(value)

      return response;
    } catch (error) {
      console.error('Error posting like/dislike:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
  
  
  
  


}));