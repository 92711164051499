import React, { useState, useEffect } from 'react';
import '../../../Static/Style/RegisterCss/Register.css';
import Logo from '../../../assets/colabrary-logo-2.png';
import { Link, useNavigate } from 'react-router-dom';
import { useAccountStore } from '../../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = ({ onClose, onLoginClick }) => {
  const [formData, setFormData] = useState({
    accountFor: 'self',
    name: '',
    password: '',
    email: '',
    emailotp: '',
    number: '',
    numberOtp: ''
  });

  const [otpVisible, setOtpVisible] = useState(false);
  const [numberOtpVisible, setNumberOtpVisible] = useState(false);
  const { generateEmailOtp, generateMobileOtp,createUser } = useAccountStore();
  const navigate = useNavigate();


  const [emailOtpCountdown, setEmailOtpCountdown] = useState(0); 
  const [numberOtpCountdown, setNumberOtpCountdown] = useState(0);
  const [isEmailOtpSent, setIsEmailOtpSent] = useState(false);
  const [isMobileOtpSent, setIsMobileOtpSent] = useState(false);

  useEffect(() => {
    if (emailOtpCountdown > 0) {
      const timer = setTimeout(() => setEmailOtpCountdown(emailOtpCountdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [emailOtpCountdown]);

  useEffect(() => {
    if (numberOtpCountdown > 0) {
      const timer = setTimeout(() => setNumberOtpCountdown(numberOtpCountdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [numberOtpCountdown]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const validateFields = () => {
    const { name, password, email, emailotp, number, numberOtp } = formData;

    if (name.length < 3) {
      toast.error('Name must be at least 3 characters.');
      return false;
    }

    if (password.length < 6) {
      toast.error('Password must be at least 6 character.');
      return false;
    }

    if (!email.includes('@')) {
      toast.error('Please enter a valid email.');
      return false;
    }

    if (otpVisible && (emailotp.length !== 6 || isNaN(emailotp))) {
      toast.error('Email OTP must be 6 digits.');
      return false;
    }

    if (number.length !== 10 || isNaN(number)) {
      toast.error('Please enter a valid number.');
      return false;
    }

    if (numberOtpVisible && (numberOtp.length !== 6 || isNaN(numberOtp))) {
      toast.error('Mobile OTP must be 6 digits.');
      return false;
    }

    return true;
  };

  const handleSendEmailOtp = async () => {
    if (!formData.email) {
      toast.error('Please enter your email ID');
      return;
    }

    try {
      await generateEmailOtp(formData.email);
      toast.success('OTP has been sent to your email');
      setOtpVisible(true);
      setEmailOtpCountdown(30);
      setIsEmailOtpSent(true);
    } catch (error) {
      if (error.response && error.response.data === 'Email Already Registered.') {
        toast.error('Email already registered. Please login.');
      } else {
        toast.error('Failed to send OTP. Please try again later.');
      }
    }
  };

  const handleSendNumberOtp = async () => {
    if (!formData.number) {
      toast.error('Please enter your mobile number');
      return;
    }

    try {
      await generateMobileOtp(formData.number);
      toast.success('OTP has been sent to your mobile');
      setNumberOtpVisible(true);
      setNumberOtpCountdown(30); 
      setIsMobileOtpSent(true);
    } catch (error) {
      if (error.response && error.response.data === 'Mobile Already Registered.') {
        toast.error('Mobile already registered. Please login.');
      } else {
        toast.error('Failed to send OTP. Please try again later.');
      }
    }
  };

  const handleRegister = async() => {
    if (!validateFields()) {
      return;
    }

    if (otpVisible && !formData.emailotp) {
      toast.error('Please enter the Email OTP');
      return;
    }

    if (numberOtpVisible && !formData.numberOtp) {
      toast.error('Please enter the Mobile OTP');
      return;
    }

    try {
      const response = await createUser({
        name: formData.name,
        acc_For: formData.accountFor,
        password: formData.password,
        email: formData.email,
        email_otp: formData.emailotp,
        contact: formData.number,
        phone_otp: formData.numberOtp
      });
  

      if (response.status === 200) {
        onClose();
        navigate('/');
        toast.success('Registration successful');
        console.log('User registered:', response.data);
  
      } else {
        toast.error('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during registration:', error);
  
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || 'Registration failed');
      } else {
        toast.error('Something went wrong. Please try again later.');
      }
    }
  };

  return (
    <div className="yu-register-page">
      <div className="yu-register-form">
        <div className="logo-con">
          <img src={Logo} alt="Logo" />
        </div>
        <h2 className="welcome-message">Create your account</h2>
        <div className="yu-form-section">
          <div className="yu-form-section-1">
            <div className="yu-form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="yu-form-group">
              <label htmlFor="accountFor">Account For</label>
              <select id="accountFor" value={formData.accountFor} onChange={handleInputChange}>
                <option value="0">Self</option>
                <option value="1">Children</option>
              </select>
            </div>

            <div className="yu-form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="yu-form-section-2">
            <div className="yu-form-group">
              <label htmlFor="email">Email</label>
              <div className="yu-form-input">
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <button onClick={handleSendEmailOtp} disabled={emailOtpCountdown > 0}>
                  {emailOtpCountdown > 0 ? ` ${emailOtpCountdown}s` : 'Get OTP'}
                </button>
              </div>
            </div>

            {otpVisible && (
              <div className="yu-form-group">
                <label htmlFor="emailotp">Email OTP</label>
                <input
                  type="text"
                  id="emailotp"
                  placeholder="Enter OTP"
                  value={formData.emailotp}
                  onChange={handleInputChange}
                />
              </div>
            )}

            <div className="yu-form-group">
              <label htmlFor="number">Mobile Number</label>
              <div className="yu-form-input">
                <input
                  type="text"
                  id="number"
                  placeholder="Enter your mobile number"
                  value={formData.number}
                  onChange={handleInputChange}
                />
                <button onClick={handleSendNumberOtp} disabled={numberOtpCountdown > 0}>
                  {numberOtpCountdown > 0 ? ` ${numberOtpCountdown}s` : 'Get OTP'}
                </button>
              </div>
            </div>

            {numberOtpVisible && (
              <div className="yu-form-group">
                <label htmlFor="numberOtp">Mobile OTP</label>
                <input
                  type="text"
                  id="numberOtp"
                  placeholder="Enter Mobile OTP"
                  value={formData.numberOtp}
                  onChange={handleInputChange}
                />
              </div>
            )}
          </div>
        </div>

        <div className="yu-register-button">
          <button onClick={onClose} style={{ background: 'var(--lightblue)', color: 'var(--black)' }}>
            Cancel
          </button>
          <button onClick={handleRegister}
          disabled={!isEmailOtpSent || !isMobileOtpSent}>Register</button>
        </div>

        <div className="exist-account">
          <p>
            Already have an account?{' '}
            <Link onClick={onLoginClick}>
              Login here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
