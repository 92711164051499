import React, { useState } from 'react';
import '../../Static/Style/YuSearchResult.css';

const YuSearchResults = () => {
    const [activeMenu, setActiveMenu] = useState(null); // State to track which result menu is active

    const searchResults = [
        {
            id: 1,
            title: 'title',
            description: 'Learn the basics of React in this comprehensive tutorial...',
            channelName: 'Code Academy',
            views: '1.2M views',
            uploadTime: '1 week ago',
            thumbnail: "https://img.youtube.com/vi/nSb5QdG2PCE/hqdefault.jpg"
        },
        {
            id: 2,
            title: 'title',
            description: 'Learn the basics of React in this comprehensive tutorial...',
            channelName: 'Code Academy',
            views: '1.2M views',
            uploadTime: '1 week ago',
            thumbnail: "https://img.youtube.com/vi/nSb5QdG2PCE/hqdefault.jpg"
        },
        {
            id: 3,
            title: 'title',
            description: 'Learn the basics of React in this comprehensive tutorial...',
            channelName: 'Code Academy',
            views: '1.2M views',
            uploadTime: '1 week ago',
            thumbnail: "https://img.youtube.com/vi/nSb5QdG2PCE/hqdefault.jpg"
        },
        {
            id: 4,
            title: 'title',
            description: 'Learn the basics of React in this comprehensive tutorial...',
            channelName: 'Code Academy',
            views: '1.2M views',
            uploadTime: '1 week ago',
            thumbnail: "https://img.youtube.com/vi/nSb5QdG2PCE/hqdefault.jpg"
        },
        {
            id: 6,
            title: 'title',
            description: 'Learn the basics of React in this comprehensive tutorial...',
            channelName: 'Code Academy',
            views: '1.2M views',
            uploadTime: '1 week ago',
            thumbnail: "https://img.youtube.com/vi/nSb5QdG2PCE/hqdefault.jpg"
        },
        {
            id: 7,
            title: 'title',
            description: 'Learn the basics of React in this comprehensive tutorial...',
            channelName: 'Code Academy',
            views: '1.2M views',
            uploadTime: '1 week ago',
            thumbnail: "https://img.youtube.com/vi/nSb5QdG2PCE/hqdefault.jpg"
        },
        // More search results...
    ];

    const toggleMenu = (id) => {
        setActiveMenu(activeMenu === id ? null : id); 
    };

    return (
        <>
        <div className='search-results-container'>
            <div className="search-results-box">
                {searchResults.map((result) => (
                    <div key={result.id} className="search-result-item">
                        <div className="thumbnail-container">
                            <img src={result.thumbnail} alt={result.title} />
                        </div>
                        <div className="result-details">
                            <h3 className="result-title">{result.title}</h3>
                            <div className="result-meta">
                                <span>{result.views} • {result.uploadTime}</span>
                                <p className='channel-name'>{result.channelName}</p>
                            </div>
                            <p className="result-description">{result.description}</p>
                        </div>
                        <span 
                            className="search-result-setting-dots-icon" 
                            onClick={() => toggleMenu(result.id)}
                        >•••</span>

                        {/* Conditional rendering of the options menu */}
                        {activeMenu === result.id && (
                            <div className="options-menu"style={{
                                position:"relative"
                            }}>
                                <ul style={{
                                    position:"absolute"
                                }}>
                                    <li>Save Watch Later</li>
                                    <li>Save to Playlist</li>
                                    <li>Share</li>
                                    <li>Report</li>
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
        </>
    );
};

export default YuSearchResults;
