import { create } from 'zustand';
import axios from 'axios';

export const useChannelStore = create((set) => ({
  currentChannel: null,
  authToken:null,

 

  createChannel: async (payload) => {

      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error("No token available for authentication.");
      }
    
      try {
        const response = await axios.post(
          'http://127.0.0.1:8000/yu/create-channel/',
          {
            user: payload.user,
            name: payload.name,
            description: payload.description,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
      

        set({ currentChannel: response.data, isLoggedIn: true });

    


        return response.data;
    
      } catch (error) {
        console.error('Error Channel:', error.response ? error.response.data : error.message);
        throw error;
      }
    },
    

  getChannel: async (channelID) => {

    const token = localStorage.getItem('authToken');
    if (!token) {
      return;
    }
    
    try {
      const response = await axios.get(`http://127.0.0.1:8000/yu/get-channel/${channelID}/`,{
        headers: {
          Authorization: `Token ${token}`,
        },
      })
  
      set({ currentChannel: response.data, isLoggedIn: true });
    } catch (error) {
      console.error('Error Channel:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getChannelVideos: async (payload) => {
    // const id = 2;
      try {
        const response = await axios.post(`http://127.0.0.1:8000/yu/get-all-video/`, payload)
    
        return response;
      } catch (error) {
        console.error('Error Channel:', error.response ? error.response.data : error.message);
        throw error;
      }
    }
}));