import React, {useRef} from 'react';
import '../../Static/Style/YuLeftComponet.css';
import YuVideos from './YuVideos';

const YuLeftComponent = () => {

  const sliderRef = useRef(null);

  // const scrollLeft = () => {
  //   if (sliderRef.current) {
  //     sliderRef.current.scrollBy({ left: -150, behavior: 'smooth' });
  //   }
  // };

  // const scrollRight = () => {
  //   if (sliderRef.current) {
  //     sliderRef.current.scrollBy({ left: 150, behavior: 'smooth' });
  //   }
  // };

  return (
    <div className='yu-left-side'>

      <div className='yu-categorys' ref={sliderRef}>
      {/* <button className="arrow left" onClick={scrollLeft}>&lt;</button> */}

      <h5>All</h5>   
        <h5>Education</h5>    
  
           
        {/* <button className="arrow right" onClick={scrollRight}>&gt;</button> */}

      </div>

      <div className='yu-videos-container'>
        <YuVideos />
      </div>
    </div>
  );
};

export default YuLeftComponent;
