import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import '../../Static/Style/YuVideoList.css';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import MyLoader from '../Loader/MyLoader';
import LoadingErrorPage from '../LoadingErrorPage/LoadingErrorPage';
import moment from 'moment';

const YuVideoList = ({ onVideoSelect }) => {
  const { getAllVideos, currentVideo } = useVideoStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllVideo = async () => {
      try {
        await getAllVideos();
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Videos:', error.response || error.message);
        setError('Error fetching Videos');
        setLoading(false);
      }
    };

    fetchAllVideo();
  }, [getAllVideos]);

  const handleVideoSelect = (videoId) => {
    navigate(`/video/${videoId}`);
    window.location.reload();

    if (onVideoSelect) {
      onVideoSelect(videoId);
    }
  };

  if (loading) {
    return <></>;
  }

  if (error) {
    return <LoadingErrorPage />;
  }

  return (
    <div className='yu-video-list'>
      {currentVideo && currentVideo.length > 0 ? (
        currentVideo.map((video) => (
          <div
            key={video.videoId}
            className='list-video-details'
            onClick={() => handleVideoSelect(video.id)} 
          >
            <div className='list-video-player'>
              <ReactPlayer
                url={video.video}
                light={`http://127.0.0.1:8000${video.thumbnail}`}
                controls={false}
                width='100%'
                height='100%'
              />
            </div>
            <div className='list-video-details-info'>
              <h5>{video.name}</h5>
              <span className='channelName'>Channel Name</span>
              <div>
                <span className='views'>{video.views} views</span>
                <span className='uploadTime'>{moment(video.timestamp).fromNow()}</span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No videos available</div>
      )}
    </div>
  );
};

export default YuVideoList;
