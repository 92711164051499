import React, { useState, useRef, useEffect } from 'react';
import siteLogo from '../../assets/colabrary-logo.png';
import siteMobileLogo from '../../assets/colabrary-logo-2.png';
import '../../Static/Style/yuTopNav.css';
import UploadVideo from './UploadVideo';

const YuTopNav = ({ toggleRightComponent, isRightComponentVisible }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isUploadPopupVisible, setIsUploadPopupVisible] = useState(false);

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
      setIsUploadPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openUploadPopup = () => {
    setIsUploadPopupVisible(true);
    setIsPopupVisible(false);

  };



  const handleSearchClick = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  return (
    <>
      <div className='yu-top-nav'>
        <div className='yu-site-logo'>
          <img src={siteLogo} alt="Desktop Logo" className="desktop-logo" />
          <img src={siteMobileLogo} alt="Mobile Logo" className="mobile-logo" />
        </div>
        <div className={`yu-search-box ${isSearchVisible ? 'active' : ''}`}>
          <input type="search" placeholder='Search' />
          <button className='searchButton yu-toggle-search'><span className='yu-searchBox-icon' title='Search' onClick={handleSearchClick}></span></button>
          <button className='searchVoiceButton'><span className='yu-search-voice-icon' title='Search With Your Voice'></span></button>
        </div>

        <div className='yu-top-left-icon'>
      
          <div className='create-yu' onClick={togglePopup}>
            <span className='create-yu-icon' title='Create'></span>
          </div>
          <div className='yu-notifications'>
            <span className='notifications-icon' title='Notifications'></span>
          </div>

          {isRightComponentVisible ? (
            <span onClick={toggleRightComponent} className='yu-toggle-close' title='close'>
              X
            </span>
          ) : (
            <span onClick={toggleRightComponent} className='yu-toggle-menu' title='menu'></span>
          )}
        </div>
      </div>

      {isPopupVisible && (
        <div className='create-popup' ref={popupRef}>
          <div className='create-popup-option' onClick={openUploadPopup}>
            UPLOAD VIDEO
          </div>
          <div className='create-popup-option'>GO LIVE <span style={{fontSize:"10px", position:"relative", top:"-5px", color:"var(--lightgray)" }}>(Comming Soon)</span></div>
        </div>
      )}

      {/* Render the UploadVideo component if the upload popup is visible */}
      {isUploadPopupVisible && (
        <UploadVideo onClose={() => setIsUploadPopupVisible(false)} />
      )}


    </>
  );
};

export default YuTopNav;
