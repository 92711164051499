import { create } from 'zustand';
import axios from 'axios';

export const useAccountStore = create((set) => ({
    currentUser: null,
    isLoggedIn: false,
    authToken:null,

    userLogin: async (payload) => {

      try {
        const response = await axios.post('http://127.0.0.1:8000/auth/login-user/', payload);
        console.log(response);
  
        const token = response.data.token;
        localStorage.setItem('authToken', token);
  
        set({ currentUser: response.data.user, isLoggedIn: true, authToken: token });
        return response;
      } catch (error) {
        console.error('Error during login:', error.response ? error.response.data : error.message);
        throw error;
      }
      },

      createUser: async (payload) => {
        try {
          const response = await axios.post('http://127.0.0.1:8000/auth/create-user/', payload);
          console.log(response);
          localStorage.setItem('authToken', response.data.token);
          return response;
        } catch (error) {
          console.error('Error creating user:', error);
          throw error;
        }
      },
    
      generateMobileOtp: async (contact) => {
        try {
          const response = await axios.get(`http://127.0.0.1:8000/auth/phone-otp-generate/${contact}/`);
          console.log('Mobile OTP generated:', response.data);
          return response.data;
        } catch (error) {
          console.error('Error generating mobile OTP:', error);
          throw error;
        }
      },
    
      generateEmailOtp: async (email) => {
        try {
          const response = await axios.get(`http://127.0.0.1:8000/auth/email-otp-generate/${email}/`);
          console.log('Email OTP generated:', response.data);
          return response;
        } catch (error) {
          console.error('Error generating email OTP:', error);
          throw error; 
        }
      },
                /////// user data fetch////////////////

                userDataGet: async ()=>{

            
                  const token = localStorage.getItem('authToken');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get('http://127.0.0.1:8000/auth/auth-user/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      console.log(response);
      set({ currentUser: response.data, isLoggedIn: true });
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
    },

    userlogout: () => {
      localStorage.removeItem('authToken');
      set({ currentUser: null, isLoggedIn: false, authToken: null });
    },
            

}))