import React, { useState } from 'react';
import '../../Static/Style/MyVideoCss/MyVideoCopyRight.css';
import videoThumbnail from '../../assets/62beb9f3ab302-bp-cover-image.jpg';

const dummyClaims = [
  // {
  //   id: 1,
  //   title: 'First Video',
  //   claim: 'Monetization restricted due to music',
  //   action: 'Monetization restricted - Video blocked',
  //   type: 'monetization',
  //   thumbnail: videoThumbnail,
  // },
  // {
  //   id: 2,
  //   title: 'Second Video',
  //   claim: 'Blocked due to copyrighted content',
  //   action: 'Video blocked',
  //   type: 'block',
  //   thumbnail: videoThumbnail,
  // },
  // {
  //   id: 3,
  //   title: 'Third Video',
  //   claim: 'Pending review for copyright issue',
  //   action: 'Pending review by YU Community',
  //   type: 'pending',
  //   thumbnail: videoThumbnail,
  // },
];

const CopyrightPage = () => {
  const [claimFilter, setClaimFilter] = useState('all');
  const [selectedClaim, setSelectedClaim] = useState(null);

  const handleFilterChange = (e) => {
    setClaimFilter(e.target.value);
  };

  const handleViewDetails = (claim) => {
    setSelectedClaim(claim);
  };

  const filteredClaims = dummyClaims.filter((claim) => 
    claimFilter === 'all' || claim.type === claimFilter
  );

  return (
    <div className="copyright-container">
 <div className="copyright-title">
        <h3>Copyright</h3>
      </div>

        <div className='copyright-box'>      {/* Header Section */}
      <div className="copyright-header">
        <p>Review videos with potential copyright issues and manage your content effectively.</p>
      </div>

      {/* Filter Options */}
      <div className="copyright-filter">
        <label htmlFor="claimFilter">Filter by:</label>
        <select id="claimFilter" value={claimFilter} onChange={handleFilterChange}>
          <option value="all">All claims</option>
          <option value="monetization">Monetization</option>
          <option value="block">Blocked</option>
          <option value="pending">Pending Review</option>
        </select>
      </div>

      {/* Copyright Claims List */}
      <div className="copyright-claim-list">
        {filteredClaims.length > 0 ? (
          filteredClaims.map((claim) => (
            <div className="claim-item" key={claim.id}>
              <div className="claim-thumbnail">
                <img src={claim.thumbnail} alt="Video thumbnail" />
              </div>
              <div className="claim-details">
                <h3 className="video-title">{claim.title}</h3>
                <p className="claim-status">Claim: {claim.claim}</p>
                <p className="claim-action">{claim.action}</p>
                <button 
                  className="view-details-btn" 
                  onClick={() => handleViewDetails(claim)}
                >
                  View Details
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No claims found.</p>
        )}
      </div>

      {/* Details Modal */}
      {selectedClaim && (
        <div className="details-modal">
          <div className="modal-content">
            <h3>Details for {selectedClaim.title}</h3>
            <p><strong>Claim:</strong> {selectedClaim.claim}</p>
            <p><strong>Action:</strong> {selectedClaim.action}</p>
            <button className="close-modal-btn" onClick={() => setSelectedClaim(null)}>
              Close
            </button>
          </div>
        </div>
      )}
      </div>

    </div>
  );
};

export default CopyrightPage;
