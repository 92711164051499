import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import MyVideosDashboard from '../Components/MyVideos/MyVideosDashboard';
import MyVideosContent from '../Components/MyVideos/MyVideosContent';
import CopyRightComponent from '../Components/MyVideos/CopyRightCoponent';

import MyVideoTopNav from '../Components/MyVideos/MyVideoTopNav';
import MyVideosMenu from '../Components/MyVideos/MyVideosMenu';
import '../Static/Style/MyVideoCss/YuMyVideoPage.css';

const YuMyVideosPage = () => {
  const [isVideoMenuVisible, setIsVideoMenuVisible] = useState(true);

  const toggleVideoMenu = () => {
    setIsVideoMenuVisible(prev => !prev);
  };

  return (
    <div className="my-video-container">
      <div className="top-nav">
        <MyVideoTopNav 
          toggleVideoMenu={toggleVideoMenu} 
          isVideoMenuVisible={isVideoMenuVisible} 
        />
      </div>
      <div className='my-video-box'>
        {isVideoMenuVisible && (
          <div className="my-video-menu">
            <MyVideosMenu />
          </div>
        )}
        <div className="video-content">
          <Routes>
            <Route path="/" element={<MyVideosDashboard />} />
            <Route path="content" element={<MyVideosContent />} />
            <Route path="copyright" element={<CopyRightComponent />} />

          </Routes>
        </div>
      </div>
    </div>
  );
};

export default YuMyVideosPage;
