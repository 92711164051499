import React, { useState } from 'react';
import '../../Static/Style/yuTopNav.css';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UploadVideo = ({ onClose }) => {
  const { uploadVideo } = useVideoStore();
  const { currentUser } = useAccountStore();
  const { currentChannel } = useChannelStore();

  const [formData, setFormData] = useState({
    videoFile: null,
    thumbnailFile: null,
    title: '',
    description: '',
    tags: '',
    category: '',
    privacy: 'public'
  });

  const [previewUrl, setPreviewUrl] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    const file = files[0];
    setFormData({ ...formData, [id]: file });
    if (id === 'videoFile' && file) {
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.videoFile) {
      toast.error('Select a video file.');
      return;
    }
    if (!formData.title) {
      toast.error('Provide a title.');
      return;
    }

    const userID = currentUser.id;
    const ChannelID = currentChannel?.id;
    // const ChannelID ="4";

    if (!ChannelID) {
        toast.error('Create channel First.');
        return;
    }
    const data = new FormData();
    data.append('video', formData.videoFile);
    if (formData.thumbnailFile) {
      data.append('thumbnail', formData.thumbnailFile);
    }
    data.append('name', formData.title);
    data.append('description', formData.description);
    data.append('tags', formData.tags);
    data.append('category', formData.category);
    data.append('video_type', formData.privacy);
    data.append('user', userID);
    data.append('channel', ChannelID);

    try {
      const response = await uploadVideo(data, (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      console.log('Video Uploaded:', response);
      toast.success('Video uploaded successfully!');
      onClose();
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(`Error: ${error.message}`);
    }
  };

  return (
    <>
      <div className="yu-upload-popup-overlay"></div>
      <div className="yu-upload-popup">
        <h2>Upload Video</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data"> {/* Add encType attribute */}
          <div className="yu-upload-form-group">
            {/* {previewUrl && (
              <div className="video-preview-container">
                <video src={previewUrl} controls={false}></video>
               
              </div>
            )} */}

{uploadProgress > 0 && (
                  <div className="progress-overlay">
                    <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                  </div>
                )}
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="videoFile">Select Video File</label>
            <input type="file" id="videoFile" accept="video/*" onChange={handleFileChange} />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="thumbnailFile">Select Video Thumbnail</label>
            <input type="file" id="thumbnailFile" accept="image/*" onChange={handleFileChange} />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="title">Video Title</label>
            <input 
              type="text" 
              id="title" 
              placeholder="Enter video title" 
              value={formData.title}
              onChange={handleInputChange} 
            />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="description">Video Description (optional)</label>
            <textarea 
              id="description" 
              placeholder="Add a description for your video"
              value={formData.description}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="tags">Tags (comma separated)</label>
            <input 
              type="text" 
              id="tags" 
              placeholder="Enter tags separated by commas"
              value={formData.tags}
              onChange={handleInputChange}
            />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="category">Category</label>
            <input 
              type="text" 
              id="category" 
              placeholder="Enter category separated by commas"
              value={formData.category}
              onChange={handleInputChange}
            />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="privacy">Video Privacy</label>
            <select 
              id="privacy" 
              value={formData.privacy} 
              onChange={handleInputChange}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
          </div>
          <div className="yu-upload-form-group-button">
            <button type="button" className="close-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit">Upload Video</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UploadVideo;
