import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import '../../Static/Style/yuVideos.css';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import moment from 'moment';
import MyLoader from '../Loader/MyLoader';
import LoadingErrorPage from '../LoadingErrorPage/LoadingErrorPage';
const YuVideos = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  const { getAllVideos, currentVideo } = useVideoStore();

  useEffect(() => {
    const fetchAllVideo = async () => {
      try {
        const videoResponse = await getAllVideos();
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Videos:', error.response || error.message);
        setError('Error fetching Videos');
        setLoading(false);
      }
    };

    fetchAllVideo();
  }, []);

  if (loading) {
    return <div><MyLoader/></div>;
  }

  if (error) {
    return <div><LoadingErrorPage/></div>;
  }

  const sortedVideos = [...currentVideo].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));


  const placeholderImage = 'https://via.placeholder.com/300x200.png?text=Loading+Thumbnail';


  return (
    <div className='yu-videos'>
      <div className='yu-trending-videos'>
        <h4>Trending Videos</h4>
        <div className='yu-trending-videos-grid'>
          {sortedVideos.map(video => (
            <div
              key={video.id}
              className='yu-trending-video-item'
              onClick={() => handleVideoClick(video.id)} 
            >
              <div className='yu-trending-video-player'>
                <ReactPlayer
                  url={video.video}
                  light={video.thumbnail ? `http://127.0.0.1:8000${video.thumbnail}` : placeholderImage}
                  width='100%'
                  height='100%'
                  controls={false}
                  playing={false}
                />
              </div>
              <h5>{video.name}</h5>
              <p>Channel Name • 500K views • {moment(video.timestamp).fromNow()}</p>
              

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YuVideos;
