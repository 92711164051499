import React from 'react';
import '../../Static/Style/MyVideoCss/MyVideosDashboard.css';
import creatorLogo from '../../assets/creatorLogo.png'

const VideosDashboard = () => {
  return (
    <div className="dashbord-container">
      <div className="dashbord-title">
        <h3>Channel Dashboard</h3>
      </div>

      <div className="dashbord-box">
        <div className="card metrics-card">
          <img src={creatorLogo} alt="creatorLogo" />
          <div className="metrics-section">
            <p>Want to see metrics on your recent video?</p>
            <p> Upload and publish a video to get started.</p>
            {/* <button className="upload-button">Upload Video</button> */}
          </div>
        </div>

        <div className="card analytics-card">
          <h3>Channel Analytics</h3>
          <div className="analytics-section">
            <div className="analytics-item">
              <h4>Current Subscribers</h4>
              <p>0</p>
              <h4>Summary - Last 28 days</h4>
              <p>Views: 0</p>
              <p>Watch time (hours): 0</p>
              <button>Go to Channel Analytics</button>
            </div>
          </div>
        </div>

        <div className="card letest-comment-card">
          <h3>Latest Comments</h3>
          <div className="letest-comment-section">
            <div className="comment-item">
              <p>No comments yet. Engage with your viewers!</p>
              <button>View More</button>
            </div>
          </div>
        </div>

        <div className="card subscribers-card">
          <h3>Recent Subscribers</h3>
          <div className="letest-subscribers-section">
            <div className="letest-subscribers-item">
              <p>No subscribers.!</p>
              <button>See All</button>
            </div>
          </div>
        </div>

        {/* Fifth Card - YU Studio Updates */}
        <div className="card YU-studio-card">
          <h3>What's new in YU</h3>
          <div className="YU-studio-section">
            <div className="YU-studio-item">
              <p>
                Simplified channel page layout and optional "Home tab"<br />
                Expansion of channel permissions<br />
                Upcoming changes to Community Guidelines warnings
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosDashboard;
