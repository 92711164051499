import React, { useState } from 'react';
import '../../../Static/Style/LoginCss/Login.css';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/colabrary-logo-2.png';
import { useAccountStore } from '../../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify'; 

const LoginPage = ({ onClose, onCreateAccountClick }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const { userLogin } = useAccountStore(); 

    const handleShowPassword = () => {
        setShowPassword(true);
    };

    const handleHidePassword = () => {
        setShowPassword(false);
    };

    const handleLoginIn = async () => {
        try {
          const response = await userLogin({
            email: email,
            password: password,
          });
      
          if (response.status === 200) {
            localStorage.setItem('authToken', response.data.token);
            toast.success('Login successful');
            setTimeout(() => {
                window.location.reload();
              },800); 

        }
        } catch (error) {
          console.error('Error during login:', error.response);
          if (error.response) {
            toast.error(`${error.response.data.message || 'Error occurred'}`);
          } else {
            toast.error('Login failed. Please try again later.');
          }
        }
      };
    

    return (
        <div className="yu-login-page">
            <div className="yu-login-form">
                <div className="logo-con">
                    <img src={Logo} alt="Logo" />
                </div>
                <h2 className="welcome-message">Welcome back</h2>
                <div className="email-password-input">
                    <label htmlFor="email">Email address</label>
                    <div className="email-password-div">
                        <span className="yu-email-icon yu-icons"></span>
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <label htmlFor="password">Password</label>
                    <div className="email-password-div">
                        <span className="yu-password-icon yu-icons"></span>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {showPassword ? (
                            <span
                                className="yu-password-hide-icon yu-passwordshow-icons"
                                onClick={handleHidePassword}
                                title="Hide password"
                            ></span>
                        ) : (
                            <span
                                className="yu-password-show-icon yu-passwordshow-icons"
                                onClick={handleShowPassword}
                                title="Show password"
                            ></span>
                        )}
                    </div>

                    <div className="remember-me">
                        <div className="remember-me">
                            <input
                                type="checkbox"
                                id="remember-me"
                                checked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                            />
                            <label htmlFor="remember-me">Remember</label>
                        </div>
                        <Link to="#">Forgot password?</Link>
                    </div>
                </div>

                <div className="yu-login-button">
                    <button
                        style={{ background: 'var(--lightblue)', color: 'var(--black)' }}
                        onClick={onClose}
                    >
                        <span>Cancel</span>
                    </button>
                    <button onClick={handleLoginIn}>
                        <span>Sign in</span>
                    </button>
                </div>

                <p className="create-account">
                    Don't have an account?{' '}
                    <Link onClick={onCreateAccountClick}>Create account</Link>
                </p>
            </div>
        </div>
    );
};

export default LoginPage;
