import React, { useState, useRef, useEffect } from 'react';
import siteLogo from '../../assets/colabrary-logo.png';
import siteMobileLogo from '../../assets/colabrary-logo-2.png';
import '../../Static/Style/yuTopNav.css';

const MyVideoTopNav = ({ toggleVideoMenu, isVideoMenuVisible }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isUploadPopupVisible, setIsUploadPopupVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
      setIsUploadPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openUploadPopup = () => {
    setIsUploadPopupVisible(true);
    setIsPopupVisible(false);
  };

  const handleSearchClick = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  return (
    <>
      <div className='my-video-top-nav yu-top-nav' style={{ margin: "5px 0", padding: "8px 8px", width: "100%" }}>
        <div className='yu-site-logo'>
          <img src={siteLogo} alt="Desktop Logo" className="desktop-logo" />
          <img src={siteMobileLogo} alt="Mobile Logo" className="mobile-logo" />
        </div>
        <div className={`yu-search-box ${isSearchVisible ? 'active' : ''}`}>
          <input type="search" placeholder='Search' />
          <button className='searchButton yu-toggle-search'>
            <span className='yu-searchBox-icon' title='Search' onClick={handleSearchClick}></span>
          </button>
          <button className='searchVoiceButton'>
            <span className='yu-search-voice-icon' title='Search With Your Voice'></span>
          </button>
        </div>

        <div className='yu-top-left-icon'>
          <div className='create-yu' onClick={togglePopup}>
            <span className='create-yu-icon' title='Create'></span>
          </div>
          <div className='yu-notifications'>
            <span className='notifications-icon' title='Notifications'></span>
          </div>

          <div className='yu-toggle'>
            <span className='yu-toggle-menu' title='Toggle Menu' onClick={toggleVideoMenu}>
              {isVideoMenuVisible ? 'Close Menu' : 'Open Menu'}
            </span>
          </div>
        </div>
      </div>

      {isPopupVisible && (
        <div className='create-popup' ref={popupRef}>
          <div className='create-popup-option' onClick={openUploadPopup}>
            UPLOAD VIDEO
          </div>
          <div className='create-popup-option'>GO LIVE</div>
        </div>
      )}

      {isUploadPopupVisible && (
        <>
          <div className='yu-upload-popup-overlay'></div>
          <div className="yu-upload-popup">
            <h2>Upload Video</h2>
            <form>
              <div className="yu-upload-form-group">
                <label htmlFor="videoFile">Select Video File</label>
                <input type="file" id="videoFile" accept="video/*" />
              </div>
              <div className="yu-upload-form-group">
                <label htmlFor="videoTitle">Video Title</label>
                <input type="text" id="videoTitle" placeholder="Enter video title" />
              </div>
              <div className="yu-upload-form-group">
                <label htmlFor="videoDescription">Video Description (optional)</label>
                <textarea id="videoDescription" placeholder="Add a description for your video"></textarea>
              </div>
              <div className="yu-upload-form-group">
                <label htmlFor="videoTags">Tags (comma separated)</label>
                <input type="text" id="videoTags" placeholder="Enter tags separated by commas" />
              </div>
              <div className="yu-upload-form-group">
                <label htmlFor="videoCategory">Category</label>
                <select id="videoCategory">
                  <option value="music">Music</option>
                  <option value="gaming">Gaming</option>
                  <option value="education">Education</option>
                  <option value="vlog">Vlog</option>
                  <option value="news">News</option>
                </select>
              </div>
              <div className="yu-upload-form-group">
                <label htmlFor="videoPrivacy">Video Privacy</label>
                <select id="videoPrivacy">
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </select>
              </div>
              <div className="yu-upload-form-group-button">
                <button className="close-button" onClick={() => setIsUploadPopupVisible(false)}>
                  Cancel
                </button>
                <button type="submit">Upload Video</button>
              </div>
              <div className="progress-bar">
                <div className="progress-bar-fill"></div>
              </div>
              <p className="upload-status"></p>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default MyVideoTopNav;
