import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import '../../Static/Style/LoaderCss/Loader.css';
import LoaderImg from '../../assets/colabrary-logo-2.png';

const MyLoader = () => {
    useEffect(() => {
        // GSAP animation for each character
        gsap.fromTo(
            ".loader-text span",
            {
                scale: 1,
                opacity: 0,
            },
            {
                scale: 1.5,
                opacity: 1,
                duration: 1.5,
                yoyo: true,
                repeat: -1,
                ease: "power1.inOut",
                stagger: 0.1, // Add some delay between characters
            }
        );
    }, []);

    const splitText = (text) => {
        return text.split("").map((char, index) => (
            <span key={index} className="char">{char}</span>
        ));
    };

    return (
        <div className="loader">
            <div className="loader-content">
                <img src={LoaderImg} alt="Loading..." className="loader-logo" />
                <div className="loader-text">
                    {splitText("Loading...")}
                </div>
            </div>
        </div>
    );
};

export default MyLoader;
