import React, { useState } from 'react';
import LoginPage from '../Auth/LoginPage/Login';
import Register from '../Auth/RegisterPage/Register';
import '../../Static/Style/AuthCss/Auth.css';

const Auth = ({ onClose }) => {
    const [showLogin, setShowLogin] = useState(true);
    const [showRegister, setShowRegister] = useState(false);

    const handleShowRegister = () => {
        setShowLogin(false);
        setShowRegister(true);
    };

    const handleShowLogin = () => {
        setShowRegister(false);
        setShowLogin(true);
    };

    const handleClose = () => {
        setShowLogin(false);
        setShowRegister(false);
        onClose();
    };

    return (
        <div className="auth-container">
            
            {showLogin && (
                <LoginPage
                    onClose={handleClose}
                    onCreateAccountClick={handleShowRegister}
                />
            )}
            {showRegister && (
                <Register
                    onClose={handleClose}
                    onLoginClick={handleShowLogin}
                />
            )}
        </div>
    );
};

export default Auth;
